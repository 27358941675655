import {LitElement, html, css} from 'lit';
import {hover, animate} from "motion";
import confetti from 'canvas-confetti';

const exploring = [
    {
        "name": "Physics Based Rendering"
    },
    {
        "name": "3D Sculpting"
    },
]

export class ExploringElement extends LitElement {
  static get styles() {
    return css`
      :host {
        display: block;
        position: relative;
        color: #FFF;
        font-size: 0.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .container {
        position: relative;
        width: 100%;
        height: 100%;
      }

      .content {
        position: relative;
        z-index: 1;
      }

      .topic {
        cursor: pointer;
        width: fit-content;
      }

      .canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        pointer-events: none;
      }
    `;
  }

  static get properties() {
    return { };
  }

  constructor() {
    super();
  }

  firstUpdated() {
    // let canvas = document.getElementById("canvas");
    // let myConfetti = confetti.create(canvas, {
    //     resize: true
    // });

    this.shadowRoot.querySelectorAll(".topic").forEach(box => {
        hover(box, (element) => {
            let timeout = setTimeout(() => {
                confetti({
                    spread: 100,
                    origin: {
                        y: 0.75
                    }
                });
            }, 0.5);

            animate(element, { scale: 1.3, animationDuration: 0.5 })
            return () => {
                clearTimeout(timeout);
                animate(element, { scale: 1 });
            }
        }, {});
    });
  }

  render() {
    return html`
        <div class="container">
            <div class="content">
                ${
                    exploring.map((topic) => html`
                        <div class="topic">
                            <h1>${topic.name}</h1>
                        </div>
                    `)
                }
            </div>
            
        </div>
    `;
  }
}

window.customElements.define('exploring-element', ExploringElement);
